import React from 'react';
import {Route, Redirect} from 'react-router-dom';
//Article Page
import ArticlesPage from '../pages/articles';
import ArticleAdd from '../pages/articles/addarticle';
import EditArticle from '../pages/articles/editarticle';
//Banners Page
import BannersPage from '../pages/banners';
import BannerAdd from '../pages/banners/addbanner';
import BannerEdit from '../pages/banners/editbanner';
import CandidatesPage from '../pages/candidates';
import EditCandidate from '../pages/candidates/editcandidate';
// Categorie page
import CategoriesAdd from '../pages/categories/addcategory';
import CategoryEdit from '../pages/categories/editcategory';
import CategoriesPage from '../pages/categories/index';
//Course Page
import CoursesPage from '../pages/courses';
import CoursesAdd from '../pages/courses/addcourses';
import EditCourse from '../pages/courses/editcourses';
import HomePage from '../pages/home';
//Palavras Chave
import KeywordsList from '../pages/keywords/KeywordsList';
import KeywordAddEdit from '../pages/keywords/KeywordsAddEdit';
// Root Pages
import LoginPage from '../pages/login';
import MediasAdd from '../pages/medias/MediasAdd/MediasAdd';
import MediasEdit from '../pages/medias/MediasEdit/MediasEdit';
import MediasList from '../pages/medias/MediasList/MediasList';
import PartnerAdd from '../pages/partners/addpartner';
import EditPartner from '../pages/partners/editpartner';
//Partner page
import PartnersPage from '../pages/partners/index';
import RegisterPage from '../pages/register';
// Sub Categorie page
import SubCategoriesAdd from '../pages/sub-categories/addsub-category';
import SubCategoryEdit from '../pages/sub-categories/editsub-category';
import SubCategoriesPage from '../pages/sub-categories/index';
import Uploads from '../pages/uploads';
import UsersAdminPage from '../pages/users-admin';
import UserAdminAdd from '../pages/users-admin/adduser-admin';
import EditAdminUser from '../pages/users-admin/edituser-admin';
import UserAdd from '../pages/users/adduser';
import EditUser from '../pages/users/edituser';
// User page
import UsersPage from '../pages/users/index';

// Audit page
import AuditList from '../pages/audit/AuditList';

//Reset Password page
import ResetPasswordPage from '../pages/reset-password';
import ListWaitingPage from '../pages/listWaiting';
import EmpresasPage from '../pages/empresas';
import EmpresaAdd from '../pages/empresas/addempresa';
import EditEmpresa from '../pages/empresas/editempresa';
import HomeEmpresaPage from '../pages/homeEmpresa';
import SpecialPage from '../pages/listSpecials';
import SpecialAdd from '../pages/listSpecials/addspecial';
import EditSpecial from '../pages/listSpecials/editspecial';
import ClientsPage from '../pages/clients';
import ListWaiting from '../pages/listWaiting/list';
import PrivacyPage from '../pages/PrivacyPolicy';
import CampaignsPage from '../pages/campaigns';
import EditCampaigns from '../pages/campaigns/editcampaigns';
import CampaignsAdd from '../pages/campaigns/addcampaigns';

export function PrivateRoutes() {
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

  if (adminGestor) {
    return (
      <>
        <Route path="/politica-de-privacidade" component={PrivacyPage} />

        {/* <Route path="/login" exact component={LoginPage} />
      <Route path="/register" exact component={RegisterPage} /> */}
        <Route path="/" exact component={HomePage} />
        <Route path="/audits" exact component={AuditList} />
        <Route path="/medias/edit/:mediaId" exact component={MediasEdit} />
        <Route
          path="/medias/add/:videoName/:videoType?/:videoSize?/:videoDuration?"
          exact
          component={MediasAdd}
        />

        <Route path="/users" exact component={UsersPage} />
        <Route path="/users/edit/:userId" exact component={EditUser} />
        <Route path="/users/add" exact component={UserAdd} />
        <Route path="/users/admin" exact component={UsersAdminPage} />
        <Route
          path="/users/admin/edit/:userId"
          exact
          component={EditAdminUser}
        />
        <Route path="/users/admin/add" exact component={UserAdminAdd} />

        <Route path="/clients" exact component={ClientsPage} />
        <Route path="/clients/edit/:userId" exact component={EditUser} />
        <Route path="/clients/add" exact component={UserAdd} />
        <Route path="/clients/admin" exact component={UsersAdminPage} />
        <Route
          path="/clients/admin/edit/:userId"
          exact
          component={EditAdminUser}
        />
        <Route path="/clients/admin/add" exact component={UserAdminAdd} />

        <Route path="/categories" exact component={CategoriesPage} />
        <Route path="/categories/add" exact component={CategoriesAdd} />
        <Route path="/categories/edit/:id" exact component={CategoryEdit} />
        <Route path="/sub-categories" exact component={SubCategoriesPage} />
        <Route path="/sub-categories/add" exact component={SubCategoriesAdd} />
        <Route
          path="/sub-categories/edit/:id/:category"
          exact
          component={SubCategoryEdit}
        />
        <Route path="/partners" exact component={PartnersPage} />
        <Route path="/partners/add" exact component={PartnerAdd} />
        <Route path="/partners/edit/:partnerId" exact component={EditPartner} />
        <Route path="/candidates" exact component={CandidatesPage} />
        <Route
          path="/candidates/edit/:partnerId"
          exact
          component={EditCandidate}
        />
        <Route path="/empresas" exact component={EmpresasPage} />
        <Route path="/empresas/add" exact component={EmpresaAdd} />
        <Route path="/empresas/edit/:empresaId" exact component={EditEmpresa} />
        <Route path="/specials" exact component={SpecialPage} />
        <Route path="/specials/add" exact component={SpecialAdd} />
        <Route path="/specials/edit/:specialId" exact component={EditSpecial} />
        <Route path="/listwaiting" exact component={ListWaiting} />
        <Route path="/listwaiting/add" exact component={ListWaitingPage} />
        {/* <Route path="/listwaiting/edit/:id" exact component={EditSpecial} /> */}
        <Route path="/articles" exact component={ArticlesPage} />
        <Route path="/articles/add" exact component={ArticleAdd} />
        <Route path="/articles/edit/:id" exact component={EditArticle} />
        <Route path="/courses" exact component={CoursesPage} />
        <Route path="/courses/add" exact component={CoursesAdd} />
        <Route path="/courses/edit/:id" exact component={EditCourse} />

        {/* CAMPAIGN */}
        <Route path="/campaign" exact component={CampaignsPage} />
        <Route path="/campaign/add" exact component={CampaignsAdd} />
        <Route
          path="/campaign/edit/:campaignId"
          exact
          component={EditCampaigns}
        />

        <Route path="/banners" exact component={BannersPage} />
        <Route path="/banners/add" exact component={BannerAdd} />
        <Route path="/banners/edit/:id" exact component={BannerEdit} />
        <Route path="/keywords" exact component={KeywordsList} />
        <Route path="/keywords/add" exact component={KeywordAddEdit} />
        <Route path="/keywords/edit/:id" exact component={KeywordAddEdit} />
        <Route path="/uploads" exact component={Uploads} />
        <Route path="/medias" exact component={MediasList} />
        <Route path="/reset-password" exact component={ResetPasswordPage} />
        {/* <Redirect to="/" /> 
        <Route exact path="*" render={() => <Redirect to="/" />} />*/}
        {/* <Route path="*" exact render={() => <Redirect to="/" />} /> */}
      </>
    );
  }
  return (
    <>
      <Route path="/politica-de-privacidade" component={PrivacyPage} />
      {/* <Route path="/login" exact component={LoginPage} />
      <Route path="/register" exact component={RegisterPage} /> */}
      <Route path="/" exact component={HomeEmpresaPage} />
      <Route path="/users" exact component={UsersPage} />
      <Route path="/users/edit/:userId" exact component={EditUser} />
      <Route path="/users/add" exact component={UserAdd} />
      <Route path="/users/admin" exact component={UsersAdminPage} />
      <Route path="/users/admin/edit/:userId" exact component={EditAdminUser} />
      <Route path="/users/admin/add" exact component={UserAdminAdd} />

      <Route path="/partners" exact component={PartnersPage} />
      <Route path="/partners/add" exact component={PartnerAdd} />
      <Route path="/partners/edit/:partnerId" exact component={EditPartner} />
      <Route path="/candidates" exact component={CandidatesPage} />
      <Route
        path="/candidates/edit/:partnerId"
        exact
        component={EditCandidate}
      />
      <Route path="/empresas" exact component={EmpresasPage} />
      <Route path="/empresas/add" exact component={EmpresaAdd} />
      <Route path="/empresas/edit/:empresaId" exact component={EditEmpresa} />
      <Route path="/specials" exact component={SpecialPage} />
      <Route path="/specials/add" exact component={SpecialAdd} />
      <Route path="/specials/edit/:specialId" exact component={EditSpecial} />
      <Route path="/listwaiting" exact component={ListWaiting} />
      <Route path="/listwaiting/add" exact component={ListWaitingPage} />
      {/* <Route path="/listwaiting/edit/:id" exact component={EditSpecial} /> */}
      <Route path="/reset-password" exact component={ResetPasswordPage} />
      {/* <Redirect to="/" /> */}
      {/* <Route path="*" exact render={() => <Redirect to="/" />} /> */}
    </>
  );
}
