import React, { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

import { useAppContext } from '../components/singletons/AppContext';
import api from '../core/api';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import DrawTable from './DrawTable';

const ContentsMetrics = () => {
  const { initialDate, finalDate, setAppState } = useAppContext();
  const [selectedInitialDate, handleInitialDateChange] = useState(
    moment(initialDate).format('YYYY-MM-DD'),
  );
  const [selectedFinalDate, handleFinalDateChange] = useState(
    moment(finalDate).format('YYYY-MM-DD'),
  );
  const [metrics, handleMetrics] = useState([]);

  useEffect(() => {
    const getMetrics = async () => {
      let startDate = moment(selectedInitialDate).format('YYYY-MM-DD');
      const endDate = moment(selectedFinalDate).format('YYYY-MM-DD');

      setAppState({
        initialDate: startDate,
        finalDate: endDate
      });

      if (startDate > endDate) {
        handleInitialDateChange(selectedFinalDate);
        startDate = endDate;
      }

      const contentsMetrics = await api.get(
        `/partners/contents-metrics?startDate=${startDate}&endDate=${endDate}`,
      );
      handleMetrics(contentsMetrics.data);
    };

    getMetrics();
  }, [selectedInitialDate, selectedFinalDate]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={'date-picker-box'}>
              <KeyboardDatePicker
                format="DD/MM/YY"
                value={selectedInitialDate}
                onChange={handleInitialDateChange}
              />
            </div>
            <div className={'date-picker-separator'}>—</div>
            <div className={'date-picker-box'}>
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YY"
                value={selectedFinalDate}
                onChange={handleFinalDateChange}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <ReactHTMLTableToExcel
          id="content-table-xls-button"
          className="download-table-xls-button"
          table="contents-to-xls"
          filename="conteudos"
          sheet="tablexls"
          buttonText="Exportar Conteúdos"
        />
      </div>
      {DrawTable(
        [
          'Ranking',
          'ID',
          'Título',
          'Parceiro',
          'Nº Visualizações',
          '% das Visualizações Totais',
          'Nº Visualizações Distintas',
          '% de Visualizações Distintas',
          'Nº de Salvos',
          '% de Salvos',
        ],
        metrics.map(content => {
          return [
            content.rank,
            content.id,
            content.title,
            content.partner,
            content.views,
            content.percentViews,
            content.uniqueViews,
            content.percentUniqueViews,
            content.favorites,
            content.percentFavorites,
          ];
        }),
        'contents-to-xls',
      )}
    </div>
  );
};

export default ContentsMetrics;
