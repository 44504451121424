import React, { useState, useEffect } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

import { useAppContext } from '../components/singletons/AppContext';
import api from '../core/api';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import DrawTable from './DrawTable';

const PartnersMetrics = () => {
  const { initialDate, finalDate, setAppState } = useAppContext();

  const [selectedInitialDate, handleInitialDateChange] = useState(
    moment(initialDate).format('YYYY-MM-DD'),
  );
  const [selectedFinalDate, handleFinalDateChange] = useState(
    moment(finalDate).format('YYYY-MM-DD'),
  );
  const [metrics, handleMetrics] = useState([]);

  useEffect(() => {
    getMetrics();
  }, [selectedInitialDate, selectedFinalDate]);

  const getMetrics = async () => {
    let startDate = moment(selectedInitialDate).format('YYYY-MM-DD');
    const endDate = moment(selectedFinalDate).format('YYYY-MM-DD');
    setAppState({
      initialDate: startDate,
      finalDate: endDate
    });

    if (startDate > endDate) {
      handleInitialDateChange(selectedFinalDate);
      startDate = endDate;
    }

    const partnersMetrics = await api.get(
      `/partners/partners-metrics?startDate=${startDate}&endDate=${endDate}`,
    );

    handleMetrics(partnersMetrics.data);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={'date-picker-box'}>
              <KeyboardDatePicker
                format="DD/MM/YY"
                value={selectedInitialDate}
                onChange={handleInitialDateChange}
              />
            </div>
            <div className={'date-picker-separator'}>—</div>
            <div className={'date-picker-box'}>
              <KeyboardDatePicker
                variant="inline"
                format="DD/MM/YY"
                value={selectedFinalDate}
                onChange={handleFinalDateChange}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <ReactHTMLTableToExcel
          id="partners-table-xls-button"
          className="download-table-xls-button"
          table="partners-to-xls"
          filename="parceiros"
          sheet="tablexls"
          buttonText="Exportar Parceiros"
        />
      </div>
      {DrawTable(
        [
          'ID',
          'Nome',
          'Ranking',
          'Nº Visualizações',
          '% das Visualizações Totais',
          'Nº Visualizações Distintas',
          '% de Visualizações Distintas',
          'Nº de Salvos',
          '% de Salvos',
        ],
        metrics.map(partner => {
          return [
            partner.id,
            partner.artisticName ? partner.artisticName : partner.name,
            partner.rank,
            partner.views,
            partner.percentViews,
            partner.uniqueViews,
            partner.percentUniqueViews,
            partner.favorites,
            partner.percentFavorites,
          ];
        }),
        'partners-to-xls',
      )}
    </div>
  );
};

export default PartnersMetrics;
