import React, {useState, useEffect} from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

const DrawTable = (heads, entries, id) => {
  return (
    <Table id={id}>
      <TableHead>
        <TableRow>
          {heads.map(row => {
            return <TableCell style={centralize}>{row}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {entries.map(entry => {
          return (
            <TableRow>
              {entry.map(row => {
                return <TableCell style={centralize}>{row}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const centralize = {
  textAlign: 'center',
};

export default DrawTable;
