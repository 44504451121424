import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {SnackbarProvider, useSnackbar} from 'notistack';
import useReduxState from '../../core/useReduxState';
import api from '../../core/api';
import ReturnButton from '../ReturnButton';
import {Formik} from 'formik';
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';
import RenderInput from './RenderInput';
import SearchIcon from '@material-ui/icons/Search';
import {ModalEmpresa, ModalPartner, ModalSpecial} from '../../core/formHelpers';

import Partner from '../../models/Partner';
import Empresas from '../../models/Empresas';
import Specials from '../../models/Specials';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  uploadBar: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const UserForm = ({
  user = {
    name: '',
    nickName: '',
    email: '',
    password: '',
    confirmPassword: '',
    special: false,
    partner_id: '',
    empresa_id: '',
    nomeEmpresa: '',
    special_id: '',
    nomeSpecial: '',
    author: '',
  },
  history,
}) => {
  const editing = !!user.id;
  const [getUser, setUser] = useReduxState({
    loading: false,
  });
  const [getPartners, setPartners] = useState([]);
  const [getEmpresas, setEmpresas] = useState([]);
  const [getSpecial, setSpecial] = useState([]);
  const [selectEmpresa, setSelectEmpresa] = useState(user.empresa_id);
  const [selectSpecial, setSelectSpecial] = useState(user.special_id);
  const idEmpresa = JSON.parse(localStorage.getItem('empresa_id'));
  const adminGestor = JSON.parse(localStorage.getItem('adminGestor'));

  const [open, setOpen] = useState('');
  const handleClickOpen = view => {
    setOpen(view);
  };
  const handleClose = () => {
    setOpen('');
  };

  const fetchSpecial = async () => {
    try {
      const allSpecials = await Specials.findAll();
      setSpecial(allSpecials);
    } catch (e) {
      console.log({e});
    }
  };

  const fetchEmpresa = async () => {
    try {
      const allEmpresas = await Empresas.findAll();
      // console.log(allEmpresas);
      setEmpresas(allEmpresas);
    } catch (e) {
      // console.log({e});
    }
  };

  const fetchApi = async () => {
    try {
      const allPartners = await Partner.findAll();
      setPartners(allPartners);
    } catch (e) {
      // console.log({e});
    }
  };

  useEffect(() => {
    fetchApi();
    fetchEmpresa();
    fetchSpecial();

    if (!adminGestor) {
      setSelectEmpresa(idEmpresa);
    }
  }, []);

  const infoUserInputs = [
    {
      name: 'author',
      label: 'Relacionar a um parceiro',
      type: 'text',
      leftIcon: (
        <IconButton onClick={() => handleClickOpen('partner')}>
          <SearchIcon />
        </IconButton>
      ),
      onSearch: () => void 0,
    },
  ];

  const infoEmpresaInputs = [
    {
      name: 'nomeEmpresa',
      label: 'Relacionar a uma empresa',
      type: 'text',
      leftIcon: (
        <IconButton onClick={() => handleClickOpen('empresa')}>
          <SearchIcon />
        </IconButton>
      ),
      onSearch: () => void 0,
    },
  ];

  const infoSpecialInputs = [
    {
      name: 'nomeSpecial',
      label: 'Categoria Especial',
      type: 'text',
      leftIcon: (
        <IconButton onClick={() => handleClickOpen('special')}>
          <SearchIcon />
        </IconButton>
      ),
      onSearch: () => void 0,
    },
  ];

  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = async form => {
    form.partner_id = form.partnerId || null;
    delete form.partnerId;

    form.special_id = selectSpecial || null;
    form.empresa_id = selectEmpresa || null;

    // console.log(form);

    setUser({loading: true});

    if (form.special == true) {
      form.premium = 'S';
    } else {
      form.premium = user.premium === 'S' ? 'F' : user.premium;
    }

    const redirect = `/users${form.isAdmin ? '/admin' : ''}`;

    try {
      if (user.id) {
        if (form.password) {
          delete form.confirmPassword;
        }
        await api
          .put(`/users/${user.id}`, form)
          .then(res => {
            if (res.status === 200) {
              enqueueSnackbar('Dados alterados com sucesso!');
              history.push(redirect);
            }
          })
          .catch(e => {
            enqueueSnackbar('Ocorreu um erro', {
              variant: 'error',
            });
            setUser({loading: false});
          });
      } else {
        delete form.confirmPassword;
        await api
          .post(`/auth/register`, form)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              enqueueSnackbar('Dados inseridos com sucesso!');
              history.push(redirect);
            }
          })
          .catch(e => {
            enqueueSnackbar('Ocorreu um erro', {
              variant: 'error',
            });
            setUser({loading: false});
          });
      }
    } catch (e) {
      setUser({loading: false});
    }
  };

  const validateForm = values => {
    const errors = {};
    let errorMessage = '';

    if (!values.name) {
      errors.name = 'Digite um nome';
      errorMessage += 'Nome\n';
    }

    if (!values.email) {
      errors.email = 'Digite um email';
      errorMessage += 'Email\n';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Email inválido';
      errorMessage += 'Email inválido\n';
    }

    if (!user.id && !values.password) {
      errors.password = 'Digite uma senha';
      errorMessage += 'Senha\n';
    }

    if (values.password) {
      if (values.password != values.confirmPassword) {
        errors.confirmPassword = 'Senhas não são iguais';
        errorMessage += 'Confirmar senha\n';
      }
    }

    if (Object.keys(errors).length) {
      enqueueSnackbar(
        'Formulário incompleto, campos a serem preenchidos:\n' + errorMessage,
        {
          preventDuplicate: true,
          variant: 'error',
          style: {whiteSpace: 'pre-line'},
        },
      );
    }

    return errors;
  };

  const getPartnerFullInfo = () => {};
  // console.log(getPartners);
  console.log(getPartnerFullInfo());

  const getEmpresaFullInfo = () => {};
  // console.log(getEmpresas);
  console.log(getEmpresaFullInfo());

  const getSpecialFullInfo = () => {};
  // console.log(getSpecial);
  console.log(getSpecialFullInfo());

  return (
    <Formik
      initialValues={user}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formikProps => (
        <Grid container xs={12}>
          <Grid item xs={12} alignContent="center">
            <Typography component="h1" variant="h5">
              <ReturnButton />
              {editing ? 'Atualizar Dados' : 'Cadastrar Novo Usuário'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {!getUser().loading ? (
              <form onSubmit={formikProps.handleSubmit}>
                {!user.isAdmin && (
                  <>
                    <InputText
                      id="version_app"
                      label="Versão do aplicativo"
                      name="version_app"
                      value={formikProps.values.version_app}
                      autoComplete="version_app"
                      onChange={e => {
                        formikProps.setFieldValue(
                          'version_app',
                          e.target.value,
                        );
                        formikProps.errors['version_app'] = null;
                      }}
                    />
                    <InputText
                      id="version_device"
                      label="Versão do dispositivo"
                      name="version_device"
                      value={formikProps.values.version_device}
                      autoComplete="version_device"
                      onChange={e => {
                        formikProps.setFieldValue(
                          'version_device',
                          e.target.value,
                        );
                        formikProps.errors['version_device'] = null;
                      }}
                    />
                  </>
                )}
                <InputText
                  errorMessage={
                    formikProps.touched.name && formikProps.errors.name
                  }
                  id="name"
                  label="Nome"
                  name="name"
                  value={formikProps.values.name}
                  autoComplete="name"
                  onChange={e => {
                    formikProps.setFieldValue('name', e.target.value);
                    formikProps.errors['name'] = null;
                  }}
                />
                <InputText
                  errorMessage={
                    formikProps.touched.nickName && formikProps.errors.nickName
                  }
                  id="nickName"
                  label="Apelido"
                  name="nickName"
                  value={formikProps.values.nickName}
                  autoComplete="nickName"
                  onChange={e =>
                    formikProps.setFieldValue('nickName', e.target.value)
                  }
                />

                <InputText
                  errorMessage={
                    formikProps.touched.email && formikProps.errors.email
                  }
                  id="email"
                  label="Email"
                  name="email"
                  value={formikProps.values.email}
                  autoComplete="email"
                  onChange={e => {
                    formikProps.setFieldValue('email', e.target.value);
                    formikProps.errors['email'] = null;
                  }}
                />

                <InputText
                  errorMessage={
                    formikProps.touched.password && formikProps.errors.password
                  }
                  id="password"
                  label="Senha"
                  name="password"
                  type="password"
                  value={formikProps.values.password}
                  autoComplete="password"
                  onChange={e => {
                    formikProps.setFieldValue('password', e.target.value);
                    formikProps.errors['password'] = null;
                  }}
                />

                <InputText
                  errorMessage={
                    formikProps.touched.confirmPassword &&
                    formikProps.errors.confirmPassword
                  }
                  id="confirmPassword"
                  label="Confirmar Senha"
                  name="confirmPassword"
                  type="password"
                  value={formikProps.values.confirmPassword}
                  autoComplete="confirmPassword"
                  onChange={e => {
                    formikProps.setFieldValue(
                      'confirmPassword',
                      e.target.value,
                    );
                    formikProps.errors['confirmPassword'] = null;
                  }}
                />
                {/* 
                <ModalEmpresa
                  formikProps={formikProps}
                  empresas={getEmpresas}
                  open={open}
                  handleClose={handleClose}
                />
                <RenderInput
                  inputs={infoEmpresaInputs}
                  formikProps={formikProps}
                />

                <ModalSpecial
                  formikProps={formikProps}
                  specials={getSpecial}
                  open={open}
                  handleClose={handleClose}
                />
                <RenderInput
                  inputs={infoSpecialInputs}
                  formikProps={formikProps}
                /> */}

                <div style={{display: 'flex', justifyContent: 'space_between'}}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%',
                      height: '100px',
                      marginRight: 50,
                    }}>
                    <InputLabel style={{paddingLeft: '15px'}}>
                      Selecione a empresa
                    </InputLabel>

                    <Select
                      style={{
                        borderRadius: '40px',
                        height: 50,
                        border: 'solid 0.5px #d3d3d3',
                        padding: '0 20px',
                      }}
                      variant="outlined"
                      value={selectEmpresa}
                      label="Selecione a empresa"
                      disabled={!adminGestor}
                      onChange={e => setSelectEmpresa(e.target.value)}>
                      {getEmpresas &&
                        getEmpresas.map(e => {
                          return (
                            <MenuItem value={e.id}>{e.nomeFantasia}</MenuItem>
                          );
                        })}
                    </Select>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '50%',
                    }}>
                    <InputLabel style={{paddingLeft: '15px'}}>
                      Selecione a categoria
                    </InputLabel>
                    <Select
                      style={{
                        borderRadius: '40px',
                        height: 50,
                        border: 'solid 0.5px #d3d3d3',
                        padding: '0 20px',
                      }}
                      variant="outlined"
                      value={selectSpecial}
                      label="Selecione a categoria"
                      onChange={e => setSelectSpecial(e.target.value)}>
                      {getSpecial &&
                        getSpecial.map(e => {
                          return <MenuItem value={e.id}>{e.nome}</MenuItem>;
                        })}
                    </Select>
                  </div>
                </div>

                <ModalPartner
                  formikProps={formikProps}
                  partners={getPartners}
                  open={open}
                  handleClose={handleClose}
                />
                <RenderInput
                  inputs={infoUserInputs}
                  formikProps={formikProps}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formikProps.values.special}
                      onChange={e => {
                        formikProps.setFieldValue('special', e.target.checked);
                      }}
                      name="special"
                      color="primary"
                    />
                  }
                  label="Parceiro Especial"
                  style={{
                    marginTop: 20,
                    marginLeft: '20px',
                  }}
                />

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <ButtonSubmit
                    style={{
                      marginTop: 20,
                      width: '20vw',
                      alignSelf: 'center',
                    }}
                    type="submit"
                    variant="contained"
                    color="primary">
                    Salvar
                  </ButtonSubmit>
                </div>
              </form>
            ) : (
              <div
                className="flex-col center-a center-b"
                style={{height: '50vh'}}>
                <CircularProgress size={100} />
                <p> Carregando... </p>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default UserForm;
